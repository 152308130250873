import * as React from "react";
import Section from "../components/section";
import PromoBanner from "../components/promoBanner";

export default function CoursePromo() {
    return (
        <Section>
            <PromoBanner />
        </Section>
    );
}

