import * as React from "react";
import * as styles from "../styles/variables.module.css";
import Header from "../sections/header";

export default function Layout(props) {
    return (
        <div className={styles.root} >
            <Header />
            <main>
                {props.children}
            </main>
        </div>
    );
}