import * as React from "react";
import Section from "../components/section";
import * as style from "./aboutMe.module.css"
import {StaticImage} from "gatsby-plugin-image";
import {
    FaFacebookSquare,
    FaInstagramSquare,
    FaLinkedin,
    FaTwitterSquare
} from "react-icons/fa";

export default function AboutMe() {
    return (
        <Section id="about-me">
            <div className={style.root}>
                <div className={style.wrapper}>
                    <p className={style.head}>
                        O mnie
                    </p>
                    <h2 className={style.title}>
                        Web developer
                    </h2>
                    <p>
                        Cześć, jestem Sebastian od ponad 5 lat zajmuję się zawodowo tworzeniem aplikacji internetowych. Posiadam doświadczenie w takich technologiach jak PHP (Symfony), NodeJS (nestJS), Javascript + TypeScript, React a także w kodowaniu stron www w oparciu o HTML + CSS (SCSS). Cały czas staram się rozwijać w tym kierunku prywatnie jak i zawodowo i chciałbym się podzielić swoją wiedzą z innymi ;)
                    </p>
                    <>
                        <a href="https://twitter.com/SebaNaFroncie" target="_blank" rel="nofollow" className={style.socialmedia} aria-label="twitter"><FaTwitterSquare /></a>
                        <a href="https://www.facebook.com/sebanafroncie" target="_blank" rel="nofollow" className={style.socialmedia} aria-label="facebook"><FaFacebookSquare /></a>
                        <a href="https://www.instagram.com/sebanafroncie/" target="_blank" rel="nofollow" className={style.socialmedia} aria-label="instagram"><FaInstagramSquare /></a>
                        <a href="https://www.linkedin.com/in/sebastian-jedrysiak/" target="_blank" rel="nofollow" className={style.socialmedia} aria-label="linkedin"><FaLinkedin /></a>
                    </>
                </div>
                <div className={style.imageWrapper}>
                    <StaticImage src={'../images/me.png'} alt={'me'} />
                </div>
            </div>
        </Section>
    );
}

