import React from "react"
import * as promoBannerStyles from "./promoBanner.module.css"
import {StaticImage} from "gatsby-plugin-image";
import Button from "./button";
import * as btnStyle from "./button.module.css";

export default function PromoBanner() {
    return (
        <div className={promoBannerStyles.wrapper} id="course">
            <StaticImage
                alt="Cloudflare kurs"
                src="../images/cloudflare-course.jpg"
                className={promoBannerStyles.image}
                placeholder="blurred"
            />
            <div className={promoBannerStyles.content}>
                <h2 className={promoBannerStyles.heading}>
                    Kurs Cloudflare - kompletny przewodnik
                </h2>
                <h3 className={promoBannerStyles.description}>
                    Bezpieczeństwo + wydajność + analiza ruchu + cachowanie zapytań i wiele więcej.
                </h3>
                <a target="_blank" href="https://www.udemy.com/course/cloudflare-kompletny-przewodnik/?referralCode=3FF9D03405F089936419" rel="nofollow">
                    <Button className={btnStyle.white}>Sprawdź kurs</Button>
                </a>
            </div>
        </div>
    )
}
