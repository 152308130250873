import * as React from "react"
import {SEO} from "../components/seo";
import Layout from "../components/layout";
import Page from "../components/page";
import Hero from "../sections/hero";
import CoursePromo from "../sections/CoursePromo";
import WhatsThis from "../sections/whatsThis";
import WhatYouGet from "../sections/whatYouGet";
import AboutMe from "../sections/aboutMe";

const IndexPage = () => {
  return (
      <Layout>
          <Page>
            <Hero />
            <WhatsThis />
              <CoursePromo />
              <WhatYouGet />
              <AboutMe />
          </Page>
      </Layout>
  )
}

export default IndexPage

export const Head = () => (
    <SEO title="Kurs Cloudflare od podstaw - SebaNaFroncie" description="Kursy online dla web developerów - sebanafrocnie.pl"/>
)