import * as React from "react";
import Section from "../components/section";
import * as style from "./whatYouGet.module.css"
import SectionTitle from "../components/sectionTitle";
import TextImage from "../components/textImage";
import {GrContact} from "react-icons/gr";
import {BsBook} from "react-icons/bs";
import {AiOutlineUnlock} from "react-icons/ai";

export default function WhatYouGet() {
    return (
        <Section>
            <div className={style.root}>
                <SectionTitle head="Profity" title="Co zyskujesz?" subtitle="Co zyskasz jeśli zdecydujesz się kupić mój kurs" />
                <div className={style.wrapper}>
                    <TextImage title={"Dostęp do kursu"} subtitle={"Dożywotni dostęp do ponad 4.5h nagrań audio/video kursu na platformie udemy.com, w którym przeprowadzam cię przez każdą funkcję tego fantastycznego narzędzia po kolei. Kurs przygotowany jest tak abyś mógł go wygodnie oglądać nawet na małym ekranie."} image={<AiOutlineUnlock className={style.icon} />} />
                    <TextImage title={"Wiedza"} subtitle={"Wiedza praktyczna którą będziesz mógł wykorzystać czy to w swojej aplikacji czy aplikacji twojego klienta, oraz świadomość rozwiązań które cloudflare oferuje. Prawdopodobnie o wielu z nich nigdy nie słyszałeś."} image={<BsBook className={style.icon} />} />
                    <TextImage title={"Kontakt ze mną"} subtitle={"Możliwość kontaktu ze mna. Masz jakieś pytania, sugestie, wątpliwości? Na każdą wiadomość chętnie odpowiem najszybciej jak to możliwe."} image={ <GrContact className={style.icon} />}/>
                </div>
            </div>
        </Section>
    );
}

