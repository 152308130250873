import React from "react";
import * as style from "./sectionTitle.module.css";

export default function SectionTitle({ className = "", head, title, subtitle, ...props }: {className?: string; head: string, title: string, subtitle: string}) {
    return (
        <div {...props} className={[style.root, className].join(" ")} >
            <p className={style.head}>
                {head}
            </p>
            <h2 className={style.title}>
                {title}
            </h2>
            <p className={style.subtitle}>
                {subtitle}
            </p>
        </div>
    );
}