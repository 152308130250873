import React from "react"
import {useSiteMetadata} from "../hooks/use-site-metadata";

export const SEO = ({ title, description, pathname }: {title?: string, description?: string, pathname?: string}) => {
    const { title: defaultTitle, description: defaultDescription, siteUrl, twitterUsername } = useSiteMetadata()

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        url: `${siteUrl}${pathname || ``}`,
        twitterUsername,
    }

    return (
        <>
            <title>{seo.title}</title>
            <meta name="description" content={seo.description} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={seo.title} />
            <meta name="twitter:url" content={seo.url} />
            <meta name="twitter:description" content={seo.description} />
            <meta name="twitter:creator" content={seo.twitterUsername} />
            <meta name="twitter:creator" content={seo.twitterUsername} />
            <meta property="og:image" content={"https://sebanafroncie.pl/cloudflare-course.jpg"} />
             <meta property="og:image:width" content={"750"} />
            <meta property="og:image:height" content={"422"} />
             <meta property="og:type" content="website" />
             <meta property="og:title" content="Kurs Cloudflare od podstaw - SebaNaFroncie" />
            <meta property="og:description" content={"Kursy online dla web developerów - sebanafrocnie.pl"} />
             <meta property="og:site_name" content={"Sebanafroncie"} />
             <meta property="og:url" content={"https://sebanafroncie.pl"} />
        </>
    )
}