import * as React from "react";
import * as styles from "./hero.module.css";
import Section from "../components/section";
import Button from "../components/button";
import * as style from "./hero.module.css"
import {StaticImage} from "gatsby-plugin-image";
export default function Hero() {
    return (
        <Section>
            <div className={styles.root}>
                <div className={styles.imageWrapper}>
                    <StaticImage
                        alt="SebaNaFroncie"
                        src="../images/hero.png"
                        placeholder="blurred"
                        width={592}
                    />
                </div>
                <div className={styles.wrapper}>
                    <p className={style.testimonial}>Seba na froncie</p>
                    <h1 className={style.headline}>Jedyny taki kurs Cloudflare w internecie!</h1>
                    <h2 className={style.description}>Bezpieczeństwo + wydajność + analiza ruchu + cachowanie + więcej</h2>
                    <a target="_blank" href="https://www.udemy.com/course/cloudflare-kompletny-przewodnik/?referralCode=3FF9D03405F089936419" rel="nofollow">
                        <Button>Sprawdź kurs</Button>
                    </a>
                </div>
            </div>
        </Section>
    );
}

