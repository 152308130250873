import React from "react";
import * as style from "./textImage.module.css";

export default function TextImage({ className = "", title, subtitle, image, ...props }: {className?: string; title: string, subtitle: string, image: JSX.Element}) {
    return <div {...props} className={[style.root, className].join(" ")} >
        {image}
        <p className={style.title}>
            {title}
        </p>
        <p className={style.subtitle}>
            {subtitle}
        </p>
    </div>;
}