import * as React from "react";
import Section from "../components/section";
import * as style from "./whatsThis.module.css"
import SectionTitle from "../components/sectionTitle";
import TextImageWide from "../components/textImageWide";
import {StaticImage} from "gatsby-plugin-image";

export default function WhatsThis() {

    return (
        <Section id="cloudflare">
            <div className={style.root}>
                <SectionTitle head="Lista funkcji" title="Co to jest Cloudflare?" subtitle="Między innymi" />
                <div className={style.wrapper}>
                    <TextImageWide head={"Funkcja"} title={"Bezpieczeństwo"} subtitle={"Cloudflare zapewnia wszelkiego rodzaju ochronę dla twojej aplikacji webowej (bez konieczności konfiguracji), jak również daje możlwości ustawiania własnych reguł bezpieczeństwa."} image={                <StaticImage
                        alt="SebaNaFroncie"
                        src="../images/safety.svg"
                        placeholder="blurred"
                        width={350}
                    />} />
                    <TextImageWide head={"Funkcja"} title={"Optymalizacja"} subtitle={"Umożliwia optymalizację pilków dostarczanych użytkownikowi a także daje możliwość cachowania ich na swoim serwerze dzięki czemu użytkownik dostaje zasoby szybciej, a ty masz odciążony serwer."} image={                <StaticImage
                        alt="SebaNaFroncie"
                        src="../images/speed.svg"
                        placeholder="blurred"
                        width={350}
                    />} imageFirst={true} />
                    <TextImageWide head={"Funkcja"} title={"Narzędzia"} subtitle={"Zestaw narzędzi które wspomogą twoją aplikację, czy to sprawdzając stan aplikacji 24/7, czy nawet możesz mieć swoją frontendową stronę w całości na serwerach Cloudflare, bez konieczności posiadania własnego serwera, wystarczy domena i konto na githubie/gitlabie (ta strona korzysta z tego rozwiązania), ale to tylko 2 narzędzia z wielu."} image={                <StaticImage
                        alt="SebaNaFroncie"
                        src="../images/tools.svg"
                        placeholder="blurred"
                        width={350}
                    />} />
                    <TextImageWide head={"Funkcja"} title={"Dużo więcej"} subtitle={"Jest tego dużo więcej, a z czasem dochodzą kolejne funkcjonalności. Jeśli chciałbyś je poznać, i zobaczyć jak się ich używa, to zapraszam cię serdecznie do sprawdzenia mojego kursu."} image={                <StaticImage
                        alt="SebaNaFroncie"
                        src="../images/question.svg"
                        placeholder="blurred"
                        width={350}
                    />} imageFirst={true} />
                </div>
            </div>
        </Section>
    );
}

