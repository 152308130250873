import React from "react";
import * as style from "./textImageWide.module.css";

export default function TextImageWide({ className = "", head, title, subtitle, image, imageFirst, ...props }: {className?: string; head: string, title: string, subtitle: string, image: JSX.Element, imageFirst?: boolean}) {
    return <div {...props} className={[style.root, className].join(" ")} >
        <div className={style.imageWrapper}>
            {image}
        </div>
        <div className={style.item}>
            <p className={style.head}>
                {head}
            </p>
            <p className={style.title}>
                {title}
            </p>
            <p className={style.subtitle}>
                {subtitle}
            </p>
        </div>
    </div>;
}