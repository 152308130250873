import React, {useState} from "react";
import {Link} from "gatsby";
import * as style from "./header.module.css"
import * as btnStyle from "../components/button.module.css"
import Button from "../components/button";
import {StaticImage} from "gatsby-plugin-image";
import {GiHamburgerMenu} from "react-icons/gi";
import {CgClose} from "react-icons/cg";
import {IconContext} from "react-icons";
import { AnchorLink } from "gatsby-plugin-anchor-links";

export default function Header() {
    const [toggleMenu, setToggleMenu] = useState(false)
    const toggleNav = () => {
        setToggleMenu(!toggleMenu)
    }

    return (
        <header className={style.root} data-opened={toggleMenu ? true : false}>
            <Link to="/" className={style.logo}>
                <StaticImage
                    alt="SebaNaFroncie"
                    src="../images/sebanafroncie-logo.png"
                    placeholder="blurred"
                    width={150}
                />
            </Link>
            <Link to="/" className={style.logoMobile}>
                <StaticImage
                    alt="SebaNaFroncie"
                    src="../images/sebanafroncie-logo.png"
                    placeholder="blurred"
                    width={100}
                />
            </Link>
            <nav className={style.nav}>
                <ul className={style.list}>
                    <li>
                        <AnchorLink onClick={toggleNav} to="#cloudflare" className={style.link}>Cloudflare</AnchorLink>
                    </li>
                    <li>
                        <AnchorLink onClick={toggleNav} to="#course" className={style.link}>Kurs</AnchorLink>
                    </li>
                    <li>
                        <AnchorLink onClick={toggleNav} to="#about-me" className={style.link}>O mnie</AnchorLink>
                    </li>
                </ul>
            </nav>
            <a className={style.button} onClick={toggleNav} href="https://www.udemy.com/course/cloudflare-kompletny-przewodnik/?referralCode=3FF9D03405F089936419" target="_blank" rel="nofollow">
                <Button className={btnStyle.whiteBtnOnMobile}>Sprawdź kurs</Button>
            </a>
            <IconContext.Provider value={{ color: "white", size: '2em', className: style.hamburger }}>
                {toggleMenu ? <CgClose className={style.hamburger} onClick={toggleNav} /> : <GiHamburgerMenu className={style.hamburger} onClick={toggleNav} />}
            </IconContext.Provider>
        </header>
    )
}